// password reveal button
const passwordButton = document.querySelector(".btn--password");
const passwordButtonIcon = document.querySelector(".btn--password > i");
const passwordButtonText = document.querySelector(".btn--password > span");
const passwordIconRevealed = "fa-eye-slash";
const passwordIconHidden = "fa-eye";

passwordButton.addEventListener("click", function () {
  const passwordInput = document.querySelector("input#Password");
  passwordButton.child;
  if (passwordInput.getAttribute("type") === "password") {
    passwordInput.setAttribute("type", "text");
    passwordButtonIcon.classList.remove(passwordIconHidden);
    passwordButtonIcon.classList.add(passwordIconRevealed);
    passwordButtonText.innerHTML = "hide password";
  } else {
    passwordInput.setAttribute("type", "password");
    passwordButtonIcon.classList.remove(passwordIconRevealed);
    passwordButtonIcon.classList.add(passwordIconHidden);
    passwordButtonText.innerHTML = "show password";
  }
});
